@import "../../../data/styles.css";

.homepage-experience {
	width: 90%;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
}

.homepage-experience:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.homepage-experience-content {
	padding: 30px;
}

.homepage-experience-date {
	opacity: 1;
	color: var(--tertiary-color);
	font-size: 12px;
	margin-bottom: 1em;
}

.homepage-experience-title {
	opacity: 1;
	color: var(--primary-color);
	font-size: 16px;
	margin-bottom: 1em;
	font-weight: 700;
}

.homepage-experience-description {
	opacity: 1;
	color: var(--secondary-color);
	font-size: 12px;
	line-height: 1.5;
}

.homepage-experience-link {
	opacity: 1;
	padding-top: 20px;
	font-size: 15px;
	font-weight: 700;
}

.homepage-experience-link a {
	color: var(--link-color);
	text-decoration: none;
}

@media (max-width: 600px) {
	.homepage-experience {
		width: 100%;
	}
}

.experience-image {
	height: 65px;
	width: 65px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}
